import { Link } from "react-router-dom";
import AdminProducts from "../components/AdminProducts";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useStateContext } from "../contexts/ContextProvider";
import Loading from "../components/Loading";
import { useFetchContext } from "../contexts/FetchContext";
import Class from "../components/Class";
const Classes = () => {
  const { currentColor, currentMode } = useStateContext();
  const { classes } = useFetchContext();
  console.log("🚀 ~ Classes ~ classes:", classes);
  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font tracking-widest">
            Classes
          </h1>
          <div className="flex gap-2 items-center justify-center">
            <Link
              to={"/create-class"}
              className="py-1 md:py-2 dark:text-black-shade text-white-shade px-3 md:px-5 rounded-md dark:bg-white-shade bg-black-shade"
            >
              <span className="text-xs md:text-sm font-bold">Add Class</span>
            </Link>
          </div>
        </div>
        {classes === null ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loading
              color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
              bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
            />
          </div>
        ) : (
          <div className="flex flex-wrap flex-col lg:flex-row -m-4">
            {classes?.map((clazz) => {
              console.log("🚀 ~ Classes ~ clazz:", clazz);
              return (
                <Class
                  id={clazz?._id}
                  packageName={clazz?.packageId?.name}
                  morningTime={clazz?.morning}
                  evening={clazz?.evening}
                  afterNoon={clazz?.afternoon}
                  date={clazz?.date}
                />
              );
            })}
            <Link
              to={"/create-class"}
              style={{ color: currentColor }}
              className="p-4 lg:w-1/2"
            >
              <div className="h-full min-h-[10rem] border cursor-pointer hover:ring-2 dark:hover:ring-white hover:ring-black dark:border-white-shade border-black-shade rounded-md flex flex-col items-center justify-center ">
                <AiOutlinePlusCircle className="w-20 h-20" />
                <p className="text-xl ">Add Class</p>
              </div>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default Classes;
