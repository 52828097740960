import React, { useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import moment from "moment";
const EnableSlot = ({ id, date }) => {
  const { currentColor } = useStateContext();

  return (
    <div className="p-2 flex flex-col cursor-default w-full">
      <div className="h-full transition duration-300 flex flex-col w-full items-center justify-between border-black-shade dark:border-white-shade border p-4 rounded-lg">
        <div className="flex py-5 items-center justify-center w-full">
          <span
            style={{ color: currentColor }}
            className="title-font text-lg font-medium"
          >
            {moment(date).utc(false).format("ll")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EnableSlot;
