import React, { useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { BsHouse } from "react-icons/bs";
import { MdEdit, MdDelete } from "react-icons/md";
import { Modal, Fade, Backdrop } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import CustomModal from "./CustomModal";
import { BiSolidCategoryAlt } from "react-icons/bi";
const Category = ({ roomName, id }) => {
  const { currentColor } = useStateContext();
  const { UpdateCategory, DeleteCategory } = usePostContext();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleSubmit = async (values) => {
    await UpdateCategory(values, id);
    handleClose();
  };
  const deleteCategory = async () => {
    await DeleteCategory(id);
    handleDeleteClose();
  };

  //FORM SCHEMA FOR FORMIK
  const EditRoom = Yup.object({
    name: Yup.string()
      .min(3, "Minimum 3 Characters")
      .required("Room Name is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      name: roomName,
    },
    validationSchema: EditRoom,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <CustomModal open={open} handleClose={handleClose}>
        <form onSubmit={formik.handleSubmit} className="w-full max-w-2xl p-4">
          <div className="text-xl flex w-full items-center mt-2 justify-center text-black-shade ">
            {roomName}
          </div>
          <div className="relative z-0 w-full my-5 group">
            <input
              type="text"
              name="name"
              className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
              placeholder=""
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            <label
              htmlFor="name"
              className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              New Category Name
            </label>
          </div>
          {formik.touched.name && formik.errors.name && (
            <div className="text-black-shade dark:text-white-shade text-xs mt-1">
              {formik.errors.name}
            </div>
          )}
          <div className="w-full mt-5 flex justify-end mb-3">
            <button
              style={{ backgroundColor: currentColor }}
              type="submit"
              className="text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 "
            >
              Submit
            </button>
          </div>
        </form>
      </CustomModal>
      <CustomModal open={deleteOpen} handleClose={handleDeleteClose}>
        <div className="w-full flex flex-col">
          <div className="py-4 font-semibold">
            Are you sure want to delete this category ?
          </div>
          <div className="ml-auto font-semibold">
            <button
              onClick={handleDeleteClose}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
            >
              No
            </button>
            <button
              onClick={deleteCategory}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Yes
            </button>
          </div>
        </div>
      </CustomModal>
      <div className="p-2 flex flex-col cursor-default lg:w-1/3 md:w-1/2 w-full">
        <div className="h-full transition duration-300 flex flex-col w-full items-center justify-between border-black-shade dark:border-white-shade border p-4 rounded-lg">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center justify-center">
              <div className="h-16 min-w-16 rounded-full bg-black-shade dark:bg-white-shade flex items-center justify-center">
                <BiSolidCategoryAlt
                  style={{ color: currentColor }}
                  className="h-10 w-10 "
                />
              </div>
              <div className="ml-2 flex-grow">
                <h2
                  style={{ color: currentColor }}
                  className="title-font font-medium"
                >
                  {roomName}
                </h2>
              </div>
            </div>
            <div className="flex gap-2 flex-col">
              <button
                onClick={handleOpen}
                className="h-6 w-6 flex transition duration-300 items-center justify-center rounded-full hover:text-white-shade dark:hover:text-black-shade hover:bg-black-shade dark:hover:bg-white-shade cursor-pointer "
              >
                <MdEdit className="h-4 w-4" />
              </button>
              <button
                onClick={handleDeleteOpen}
                className="h-6 w-6 flex transition duration-300 items-center justify-center rounded-full hover:text-white-shade dark:hover:text-black-shade hover:bg-black-shade dark:hover:bg-white-shade cursor-pointer "
              >
                <MdDelete className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
