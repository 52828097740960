import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Login } from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import Home from "./Home";
import { useAuthContext } from "./contexts/AuthContext";
import logo from "./data/logo.png";

const App = () => {
  const { isLoggedIn } = useAuthContext();
  const { currentMode } = useStateContext();

  if (isLoggedIn === null) {
    return (
      <div className="h-screen bg-black-shade w-screen flex items-center justify-center">
        <div>
          <img
            className="mx-auto h-full w-full"
            src={logo}
            alt="Stitch Together"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={currentMode === "Dark" ? "dark" : ""}>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/*"
              element={
                isLoggedIn ? <Home /> : <Navigate to={"/login"} replace />
              }
            />
            <Route
              exact
              path="/login"
              element={
                isLoggedIn ? <Navigate to={"/dashboard"} replace /> : <Login />
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
};

export default App;
