import React from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import EnableSlot from "../components/EnableSlot";
import Loading from "../components/Loading";
const EnableSlots = () => {
  const { currentMode } = useStateContext();
  const { disabledDays } = useFetchContext();
  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font  tracking-widest">
            Disabled Days
          </h1>
          <Link
            to={"/disable-day"}
            className="py-1 md:py-2 dark:text-black-shade text-white-shade px-3 md:px-5 rounded-md dark:bg-white-shade bg-black-shade"
          >
            <span className="text-xs md:text-sm font-bold">Add Day</span>
          </Link>
        </div>
        {disabledDays === null ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loading
              color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
              bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
            />
          </div>
        ) : disabledDays !== null && disabledDays.length < 1 ? (
          <div className="h-full w-full flex items-center justify-center ">
            <p className="text-black-shade dark:text-white-shade font-semibold py-2 text-sm">
              No disbaled slots
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-4">
            {disabledDays?.map((slot) => {
              return (
                <EnableSlot key={slot?._id} id={slot?._id} date={slot?.date} />
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default EnableSlots;
