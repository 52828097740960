import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import { useNavigate } from "react-router-dom";
const CreateCategory = () => {
  const { currentColor } = useStateContext();
  const { PostCategory } = usePostContext();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    await PostCategory(values);
    formik.resetForm();
    navigate("/categories");
  };
  //FORM SCHEMA FOR FORMIK
  const CreateCategory = Yup.object({
    name: Yup.string()
      .min(3, "Minimum 3 Characters")
      .required("Category Name is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: CreateCategory,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1 className="text-3xl text-center font-medium title-font mb-10 tracking-widest">
          Create Category
        </h1>
        <div className="lg:w-4/5 mx-auto flex items-center flex-wrap">
          <div className="w-full lg:pl-10 lg:py-6 mb-6 lg:mb-0">
            <form className="max-w-md mx-auto" onSubmit={formik.handleSubmit}>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="name"
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <label
                  htmlFor="name"
                  className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Category Name
                </label>
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                  {formik.errors.name}
                </div>
              )}
              <div className="w-full flex justify-end my-6">
                <button
                  style={{ backgroundColor: currentColor }}
                  type="submit"
                  className="text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateCategory;
